import Box from '@gtg/components/box'
import Container from '@gtg/components/container'
import Seo from '@gtg/components/seo'
import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import ArticleLayout from '../components/articleLayout'
import Category from '../components/category'
import GlobalWrapper from '../components/globalWrapper'
import Subtitle from '../components/subtitle'
import Img1Src from '../images/articles/insertlens/1.jpg'
import Img4Src from '../images/articles/insertlens/4.jpg'
import { color } from '../styles/theme'

const BottomBox = styled(Box)`
  background: ${color.coal50};
  border-radius: 10px;
  box-shadow: 0px 10px 40px #e3ddd4;
`

const Img = styled.img`
  width: 100%;
  height: auto;
`

const Center = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const ArticlePage = () => {
  return (
    <GlobalWrapper>
      <Seo />
      <ArticleLayout>
        <Container wMax="sm">
          <Box px={[0, 0, 0, 3, 2, 1]} pyMax="lg">
            <Category>Lenzbox Wissen, Ratgeber</Category>
            <hr />
            <h1>Kontaktlinsen einsetzen und herausnehmen</h1>
            <Subtitle>So geht es ganz einfach</Subtitle>
          </Box>
        </Container>
        <Container wMax="lg">
          <Img src={Img1Src} />
        </Container>
        <Container wMax="sm">
          <Box px={[0, 0, 0, 3, 2, 1]} pyMax="lg">
            <p>
              Die Kontaktlinse ist eine gute Alternative zur Brille. Viele
              Brillenträger scheuen jedoch vor Kontaktlinsen zurück, da es
              Geduld und etwas Übung benötigt, um die Kontaktlinsen einzusetzen.
              Das Kontaktlinseneinsetzen ist häufig am Anfang noch ungewohnt.
              Doch mit der Zeit wird es einfacher und du wirst keine Probleme
              mehr haben, die Kontaktlinsen richtig einzusetzen.
            </p>
            <p>
              Wenn du deine Kontaktlinsen einsetzen möchtest und noch wenig
              Erfahrung mit dem Kontaktlinseneinsetzen hast, bist du hier genau
              richtig. In diesem Artikel zeigen wir dir, wie du deine
              Kontaktlinsen richtig einsetzt und worauf du dabei achten
              solltest.
            </p>
            <h2>Vorbereitung auf das Kontaktlinseneinsetzen</h2>
            <p>
              Bevor du deine Kontaktlinsen einsetzt, gibt es einige wenige sehr
              wichtige Schritte, die du beachten solltest. Da du mit deinen
              Fingern deine Kontaktlinsen einsetzt, ist die richtige Handhygiene
              von großer Bedeutung. Deine Finger müssen vor dem Kontakt mit
              deinen Kontaktlinsen sauber sein. Daher ist erstmal gründliches
              Händewaschen angesagt. Nachdem du dir deine Hände mit einem
              fusselfreien Handtuch abgetrocknet hast, kannst du dich dem
              Kontaktlinseneinsetzen widmen.
            </p>
            <p>
              Nimm nun vorsichtig mit deinem Finger die Kontaktlinsen aus der
              Verpackung oder deinem Kontaktlinsenbehälter, beispielsweise
              deiner Lenzbox. Spüle deine Kontaktlinsen mit Kochsalzlösung oder
              All-In-One Lösung ab, bevor du deine Kontaktlinsen einsetzt. Zum
              Spülen der Kontaktlinsen solltest du niemals Wasser benutzen, da
              dieses verschiedene Keime beinhalten kann, welche Entzündungen in
              deinen Augen hervorrufen können. Auch bei dem erneuten Benutzen
              deiner Monatslinsen oder Jahreslinsen solltest du vor jedem
              Kontaktlinseneinsetzen auf die{' '}
              <Link to="/kontaktlinsen-pflegen">richtige Hygiene</Link> achten.
              Um deine Kontaktlinsen perfekt vorzubereiten, kannst du eine{' '}
              <Link to="/">Lenzbox</Link> benutzen. Lege deine Linsen einfach in
              die Lenzbox, um sie per Knopfdruck zu pflegen. Sie dient als
              Aufbewahrungsbehälter, bis du deine Kontaktlinsen das nächste Mal
              einsetzen möchtest.
            </p>
            <p>
              Vergewissere dich, dass du die richtige Kontaktlinse einsetzt. Es
              empfiehlt sich hierbei eine Routine zu entwickeln, in welches Auge
              du als erstes deine Kontaktlinse einsetzt.
            </p>
            <h2>Tipps zum Einsetzen der Kontaktlinsen</h2>
            <h3>Wölbung</h3>
            <p>
              Achte vor dem Einsetzen auf die Wölbung der Kontaktlinsen. Wenn
              sich die Kontaktlinsenränder nach außen wölben und die Form eines
              Suppentellers aufweisen, sind sie falsch herum. Stülpe sie in
              diesem Fall vorsichtig mit deinen Fingern um. Deine Kontaktlinsen
              sollten nun an den Rändern keine Wölbung mehr aufweisen und sich
              perfekt an dein Auge anschmiegen.
            </p>
            <h3>Augenlid</h3>
            <p>
              Vor allem bei den ersten Versuchen Kontaktlinsen einzusetzen,
              werden sich deine Augen reflexartig schließen. Um trotzdem die
              Kontaktlinsen einsetzen zu können, kannst du dir dein Augenlid mit
              den Fingern aufhalten. Nimm die Kontaktlinse auf deinen
              Zeigefinger und ziehe mit deinem Mittelfinger dein Unterlid nach
              unten. Nutze den Zeigefinger deiner anderen Hand, um dein Oberlid
              nach oben zu ziehen und damit dein Auge weit zu öffnen. Nutze beim
              Kontaktlinseneinsetzen deinen rechten Zeigefinger für deine rechte
              Kontaktlinse und deinen linken Zeigefinger für deine linke
              Kontaktlinse.
            </p>
            <h3>Kontaktlinse einsetzen:</h3>
            <p>
              Du hast deine Hände gewaschen, die Wölbung überprüft und dein Auge
              ist weit geöffnet. Nimm nun Zeigefinger und Kontaktlinse und führe
              sie langsam auf dein Auge zu. Vermeide beim Kontaktlinseneinsetzen
              zu blinzeln und lege die Kontaktlinse vorsichtig auf dein Auge.
              Wenn du Probleme beim Einsetzen hast, kann es dir helfen, die
              Kontaktlinse nicht direkt auf die Pupille sondern auf die weiße
              Augenhaut zu legen. Platziere anschließend deine Kontaktlinse
              durch Blinzeln und Bewegung deines Auges. Wenn nötig, massiere
              deine geschlossenen Augenlieder, um deine Kontaktlinsen richtig zu
              platzieren und mögliche Luftblasen zwischen Auge und Linse zu
              entfernen.
            </p>
          </Box>
        </Container>
        <Container wMax="lg">
          <Img src={Img4Src} />
        </Container>
        <Container wMax="sm">
          <Box px={[0, 0, 0, 3, 2, 1]} pyMax="lg">
            <h2>Sitzt die Kontaktlinse richtig?</h2>
            <p>
              Deine Kontaktlinse sollte in deinem Auge kein Fremdkörpergefühl
              hervorrufen. Falls du deine Kontaktlinse nach einigen Minuten
              dennoch spürst, solltest du sie noch einmal entnehmen.
              Möglicherweise ist deine Kontaktlinse doch in die falsche Richtung
              gewölbt. Stülpe sie einfach um. Ist deine Kontaktlinse richtig
              herum und du hast ein Fremdkörpergefühl, befinden sich eventuell
              noch kleine Verschmutzungen auf ihr. In jedem Fall solltest du
              deine Kontaktlinsen vor dem erneuten Einsetzen noch einmal
              abspülen. Neben der Wölbung und möglicher Verschmutzungen können
              auch Risse und Defekte an der Kontaktlinse zu Irritationen im Auge
              führen. Benutze diese Kontaktlinse nicht weiter und tausche sie
              gegen eine Neue aus. Wenn du noch nicht häufig Kontaktlinsen
              getragen hast, kann es auch mit sauberen und nicht defekten
              Kontaktlinsen zu Beginn zu einem ungewohnten Gefühl auf dem Auge
              kommen.
            </p>
          </Box>
        </Container>
        <Container wMax="lg">
          <Center>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube-nocookie.com/embed/2dlIPuzzglE"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </Center>
        </Container>
        <Container wMax="sm">
          <Box px={[0, 0, 0, 3, 2, 1]} pyMax="lg">
            <h2>Kontaktlinse rausnehmen</h2>
            <p>
              Viele haben bei den ersten Versuchen Angst in ihr Auge zu fassen,
              wenn sie ihre Kontaktlinsen rausnehmen. Wenn du allerdings
              vorsichtig bist und mit Ruhe aufpasst, kann deinem Auge nichts
              passieren. Bevor du deine Kontaktlinsen rausnimmst, solltest du
              wieder auf die richtige Hygiene achten. Wasche auch hier zuerst
              gründlich deine Hände, damit keine Verunreinigungen in dein Auge
              gelangen. Stelle dich vor einen Spiegel und neige deinen Kopf nach
              vorne, sodass dein Kinn Richtung Brust zeigt.
            </p>
            <h3>Fingerposition</h3>
            <p>
              Wenn du mit deinem rechten Auge beginnst, nimm deine rechte Hand
              und ziehe mit deinem rechten Mittelfinger dein Unterlid nach
              unten. Ziehe im Folgenden mit deinem linken Zeigefinger dein
              Oberlid nach oben. Dein Auge sollte jetzt weit geöffnet sein,
              damit du im nächsten Schritt deine Kontaktlinsen rausnehmen
              kannst.
            </p>
            <h3>Kontaktlinse greifen</h3>
            <p>
              Dein Auge ist nun weit geöffnet. Nimm jetzt deinen rechten Daumen
              und Zeigefinger, um die Kontaktlinsen zu greifen. Schiebe die
              beiden Finger vorsichtig von außen nach innen zusammen. Durch das
              Zusammendrücken der Kontaktlinse verringerst du ihre Haftung an
              deinem Auge. Du kannst sie nun abheben und die Kontaktlinse
              rausnehmen.
            </p>
            <h3>Kontaktlinsen aufbewahren</h3>
            <p>
              Nachdem du deine Kontaktlinsen rausgenommen hast, solltest du sie
              spülen, reinigen und aufbewahren. Dazu kannst du sie in deine{' '}
              <Link to="/">Lenzbox</Link> legen. Sind beide Linsen in der
              Kammer, brauchst du nur noch den Deckel zu schließen und den Knopf
              zu drücken. Dann kannst du beim nächsten Kontaktlinseneinsetzen
              direkt auf deine frischen Linsen zurückgreifen.
            </p>
            <h2>Kontaktlinsen rausnehmen: Tricks</h2>
            <h3>Lange Fingernägel</h3>
            <p>
              Wenn du lange Fingernägel hast, könnte es für dich etwas
              schwieriger werden, deine Kontaktlinsen wieder aus dem Auge zu
              nehmen. Bei der Handhabung sind ein wenig Geschick und Übung
              gefragt, da Kontaktlinsen nicht zerkratzt werden sollten. Aber
              auch mit langen Nägeln kannst du erfolgreich deine Kontaktlinsen
              rausnehmen. Halte mit der einen Hand dein Auge auf und spreize mit
              der anderen Hand deinen Zeige- und Mittelfinger auseinander.
              Schaue in deine Handfläche, während du deine beiden Finger langsam
              schließt und so deine Kontaktlinse rausnehmen kannst.
            </p>
            <h3>Kontaktlinsensauger</h3>
            <p>
              Wenn du beim Kontaktlinsenrausnehmen Probleme haben solltest, dann
              kann dir möglicherweise ein Kontaktlinsensauger helfen. Er kann
              dir beim Kontaktlinseneinsetzen und Herausnehmen helfen. Die
              meisten Kontaktlinsensauger sind Holsauger. Ursprünglich wurden
              sie für harte Kontaktlinsen konzipiert. Mittlerweile gibt es aber
              auch einige, die sich für weiche Kontaktlinsen eignen. Durch die
              Rundung am Ende des Stabs liegt die Kontaktlinse auf dem Sauger
              auf und kann mit seiner Hilfe eingesetzt und rausgenommen werden.
              Ein Balancieren auf dem Zeigefinger ist damit nicht mehr nötig.
              Somit stellen Kontaktlinsensauger auch eine attraktive Alternative
              bei langen Fingernägeln dar. Doch auch bei den
              Kontaktlinsensaugern gilt: Übung macht den Meister.
            </p>
          </Box>
        </Container>
        <Box px={0} pt={0}>
          <Container wMax="md">
            <BottomBox pxMax="xl" pyMax="lg">
              <h2>
                Alles im Überblick: Kontaktlinsen einsetzen und rausnehmen
              </h2>
              <ul>
                <li>
                  Achte vor dem Kontakt mit deinen Kontaktlinsen unbedingt auf
                  saubere Hände.
                </li>

                <li>
                  Die Wölbung der Kontaktlinse ist entscheidend. Ist sie falsch,
                  kann es zu einem Fremdkörpergefühl kommen.
                </li>

                <li>
                  Benutze bei deinem rechten Auge deine rechte Hand und bei
                  deinem linken Auge deine linke Hand, um deine Kontaktlinsen
                  einzusetzen und rauszunehmen.
                </li>

                <li>
                  Spüle und verstaue deine Kontaktlinsen sorgfältig, eine
                  Lenzbox kann dir dabei helfen.
                </li>
                <li>
                  Deine Hilfshand ist dafür da, dass du beim Einsetzen und
                  Rausnehmen nicht blinzeln kannst und es dir damit einfacher
                  machst.
                </li>

                <li>
                  Lange Fingernägel erfordern eine andere Technik beim
                  Rausnehmen.
                </li>

                <li>
                  Wenn du Probleme mit der Technik hast, könnte dir ein
                  Kontaktlinsensauger helfen.
                </li>
              </ul>
            </BottomBox>
          </Container>
        </Box>
      </ArticleLayout>
    </GlobalWrapper>
  )
}

export default ArticlePage
